import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../../AuthContextProvider';
import { firestore } from '../../firebaseConfig'; // Add firestore import
import { doc, onSnapshot } from 'firebase/firestore'; // For Firestore functions
import axios from 'axios';
import Header from '../global/Header';
import '../../index.css'
import CheckoutButton from '../Chat/CheckoutButton';
import UnsubscribeFeedbackModal from '../global/UnsubscribeFeedbackModal';

const formatDate = timestamp => {

  // Multiply by 1000 to convert seconds to milliseconds
  const date = new Date(timestamp * 1000);

  // Format the date string
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}

export default function Subscription() {

  const user = useContext(AuthContext);
  const [userData, setUserData] = useState({})
  const [areYouSure, setAreYouSure] = useState(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const handleUnsubscribeClick = () => {
    setShowFeedbackModal(true);
  };

  const handleUnsubscribeFeedback = async (feedback) => {
    try {
      // Here you can send the feedback to your backend/database
      // Proceed with actual unsubscribe action
      console.log(2)
      const res = await axios.post("https://api.alphatwin.ai/cancel_subscription", {
        "userId": user.uid,
        "reason": feedback,
        'email': user?.email
      })
      console.log(res)

    } catch (error) {
      console.error(error)
    }
  };
  // Function to set up real-time listener for user data
  const setupUserListener = (uid) => {
    try {
      const userDocRef = doc(firestore, 'users', uid);

      // Listen for real-time updates
      const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setUserData(userData)
        } else {
          console.log("No such document!");
        }
      });

      return unsubscribe; // Return the unsubscribe function to stop listening later

    } catch (error) {
      console.error("Error setting up user listener: ", error);
    }
  }

  useEffect(() => {

    let unsubscribe;
    // Set up real-time listener if user is logged in
    if (user?.uid) {
      unsubscribe = setupUserListener(user.uid);
    }

    return () => {
      // Unsubscribe from real-time updates when component unmounts or user changes
      if (unsubscribe) {
        unsubscribe();
      }
    }
  }, [user]);
  console.log(user?.uid, userData)
  return (<div>
    <Header />
    <div className="body a2" style={{
      flexDirection: "column",
      fontStyle: "Open Sans"
    }}>

      <div className='card' style={{ fontStyle: "Open Sans", paddingRight: "24px", position: "relative" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: ".2em" }}>
          <h1 className='text' style={{
            fontSize: "x-large"
          }}>Subscription Status</h1>
          <span className='desc'>Manage your AlphaTwin Subscription</span>
        </div>

        <div style={{ display: "flex", flexDirection: "column", marginTop: "1.3em", gap: "1em" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className='text'>Current Tier</div>
            <div className={`text ${userData?.subscriptionStatus?.toLowerCase() === 'basic' ? "basic" : "username"}`}>
              {userData?.subscriptionStatus}
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className='text'>Tokens Remaining</div>
            <div className={`text`}>{userData?.tokenCount}</div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className='text'>
              {userData.cancelAtPeriodEnd ?
                <span style={{ color: "red" }}>Expiration</span>
                :
                <span>Renewal</span>
              } Date</div>
            <div className="text">{
              (userData && userData.currentPeriodEnd && userData.subscriptionStatus !== "Basic") ?
                formatDate(userData.currentPeriodEnd)
                :
                "N/A"
            }</div>
          </div>
          {areYouSure && <span style={{ position: "absolute", bottom: ".3em" }}
            className='desc'>Are you sure?</span>}
          {
            userData && (userData.subscriptionStatus == "Basic" || userData?.cancelAtPeriodEnd)
              ?
              <CheckoutButton alt={true} priceId={"price_1PxbK0KwYi8l6iBvrAFic2Oc"} />
              :
              // <button onClick={handleUnsubscribeClick} className={`${areYouSure && "AYS"} subscribe subscribe2`} >{areYouSure ?
              //   "Yes, I'm sure" : "unsubscribe"}</button>
              <a onClick={handleUnsubscribeClick} className='manage'>unsubscribe</a>
          }
        </div>
      </div>
      <div className='w1' style={{ display: "flex", gap: "1em" }}>

        <div className='card' style={{ width: "100%" }}>
          <div className='premiumTitle text'>
            <svg fill={"#58009F"} stroke-width="0" viewBox="0 0 384 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M0 256L28.5 28c2-16 15.6-28 31.8-28H228.9c15 0 27.1 12.1 27.1 27.1c0 3.2-.6 6.5-1.7 9.5L208 160H347.3c20.2 0 36.7 16.4 36.7 36.7c0 7.4-2.2 14.6-6.4 20.7l-192.2 281c-5.9 8.6-15.6 13.7-25.9 13.7h-2.9c-15.7 0-28.5-12.8-28.5-28.5c0-2.3 .3-4.6 .9-6.9L176 288H32c-17.7 0-32-14.3-32-32z"></path></svg>
            Premium Features
          </div>

          <ul className='premiumUl'>
            <li>Faster Response Times</li>
            <li>Exclusive Access to Early Features</li>
            <li>500 tokens/month</li>
          </ul>

        </div>
        <div className='card' style={{ width: "100%" }}>
          <div className='premiumTitle text'>
            <svg stroke="currentColor" fill="#58009F" stroke-width="0" viewBox="0 0 512 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path></svg>
            Customer Support
          </div>

          <ul className='premiumUl'>
            <li><a target="_blank" href='http://alphatwin.ai/#faq'>FAQ</a></li>
            <li><a target="_blank" href='maito:payments@alphatwin.ai'>payments@alphatwin.ai</a></li>
            <li><a target="_blank" href='https://calendly.com/alphatwin'>Schedule a Call</a></li>
          </ul>

        </div>
      </div>
      <UnsubscribeFeedbackModal
        isOpen={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
        onSubmit={handleUnsubscribeFeedback}
      />
    </div>
  </div>

  )
}

import React, { useContext } from 'react'
import Subscription from './Subscription'
import { AuthContext } from '../../AuthContextProvider'
import { useNavigate } from 'react-router-dom'

export default function SubscriptionHandler() {
    const user = useContext(AuthContext)
    const navigate = useNavigate()

    if (!user) {
        navigate("/")
        return null
    }

    return <Subscription />
}

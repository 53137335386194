// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Chat from './components/Chat/Chat'
import Analytics from './components/Analytics/Analytics';
import { AuthContextProvider } from './AuthContextProvider';
import SubscriptionHandler from './components/Subscription/SubscriptionHandler';
import axios from 'axios';
import { getDeviceId } from './utils/deviceId';
import Affiliate from './components/Affiliate/Affiliate';
import AffiliateDashboard from './components/Affiliate/AffiliateDashboard';
import AffiliateHandler from './components/Affiliate/AffiliateHandler';


function App() {

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const affiliateID = urlParams.get('ref');

        if (affiliateID) {
            // Optionally store it in local storage or state
            // localStorage.setItem('affiliateID', affiliateID);

            // Send event to backend
            // logEvent(affiliateID);
            axios.post("https://api.alphatwin.ai/visit", {
                deviceID: getDeviceId(),
                ref: affiliateID
            })
                .then(res => console.log(res))
                .catch(e => console.error(e))

        }
    }, []);

    return (
        <AuthContextProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<Chat />} />
                    <Route path="/analytics" element={<Analytics />} />
                    <Route path="/subscription" element={<SubscriptionHandler />} />
                    <Route path="/affiliate" element={<AffiliateHandler />} />
                </Routes>
            </Router>
        </AuthContextProvider>
    );
}

export default App;

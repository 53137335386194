import React, { useContext, useEffect, useState } from 'react';
import styles from '../../styles/Signup.module.css';
import Header from '../global/Header';
import axios from 'axios';
import { AuthContext } from '../../AuthContextProvider';
import { useNavigate } from 'react-router-dom';

export function Signup() {

    const user = useContext(AuthContext)
    const [nav, setNav] = useState(false)

    useEffect(() => {
        // useNavigate("/affiliate")
    }, [nav])

    const handleJoinClick = () => {
        axios.post('https://api.alphatwin.ai/create-affiliate', {
            "user_id": user.uid
        })
            .then(() => window.location.reload())
    }

    document.body.style.overflowY = 'scroll'

    return (
        <>
            <Header />
            <main className={styles.main} style={{ fontFamily: "Open Sans" }}>
                <div className={styles.container}>
                    <div className={styles.content}>
                        <div className={styles.header}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" style={{ color: "#58009F" }} height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mouse-pointer-click _icon_s66p4_27"><path d="m9 9 5 12 1.8-5.2L21 14Z"></path><path d="M7.2 2.2 8 5.1"></path><path d="m5.1 8-2.9-.8"></path><path d="M14 4.1 12 6"></path><path d="m6 12-1.9 2"></path></svg>
                            <h1 className={styles.title}>Become an Affiliate</h1>
                            <p className={styles.subtitle}>
                                Join our affiliate program and start earning commissions today
                            </p>
                        </div>

                        <div className={styles.features}>
                            <div className={styles.feature}>
                                <h3>33% Commission</h3>
                                <p>Earn competitive commissions on every successful referral</p>
                            </div>
                            <div className={styles.feature}>
                                <h3>7-Day Cookie</h3>
                                <p>Long cookie duration to maximize your earning potential</p>
                            </div>
                            <div className={styles.feature}>
                                <h3>Monthly Payout</h3>
                                <p>Get paid at the end of each month.</p>
                            </div>
                        </div>

                        <button onClick={handleJoinClick} className={styles.joinButton}
                            style={{ marginBottom: 0 }}>
                            Join Now
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-right "><path d="M5 12h14"></path><path d="m12 5 7 7-7 7"></path></svg>
                        </button>

                        {/* <div className={styles.stats}>
                            <div className={styles.stat}>
                                <span className={styles.statValue}>$150K+</span>
                                <span className={styles.statLabel}>Paid to Affiliates</span>
                            </div>
                            <div className={styles.stat}>
                                <span className={styles.statValue}>1,200+</span>
                                <span className={styles.statLabel}>Active Affiliates</span>
                            </div>
                            <div className={styles.stat}>
                                <span className={styles.statValue}>95%</span>
                                <span className={styles.statLabel}>Satisfaction Rate</span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </main>
        </>
    );
}
// CheckoutButton.js
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { getAuth } from 'firebase/auth';
import { getDeviceId } from '../../utils/deviceId';

const stripePromise = loadStripe('pk_test_51PxbJ8KwYi8l6iBvX8vhfVygfdXQtWcmGhI1zkY9To2vHVQwxoMPKr6JYORJ2UY0Kvgpo8aWCiyxamYIttxXgljO00Kzar097K');  // Replace with your Stripe Publishable Key

const CheckoutButton = ({ priceId, alt }) => {
    const params = new URLSearchParams(window.location.search);
    const ref = params.get('ref');

    const handleClick = async () => {
        const stripe = await stripePromise;
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
            alert('You need to log in to upgrade.');
            return;
        }

        const userId = user.uid;

        // Call your backend to create the Checkout Session
        const response = await fetch('https://api.alphatwin.ai/create-checkout-session', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ priceId, userId, ref, url: window.location.href, deviceID: getDeviceId()}),
        });

        const data = await response.json();

        if (data.error) {
            console.error(data.error);
            alert('An error occurred. Please try again.');
            return;
        }

        const sessionId = data.sessionId;

        // Redirect to Stripe Checkout
        const { error } = await stripe.redirectToCheckout({ sessionId });

        if (error) {
            console.error(error);
            alert('An error occurred during redirect.');
        }
    };
    if (alt) return <button style={{
        cursor: "pointer"
    }} className='sub' onClick={handleClick}>Subscribe</button>
    return <a className='subscribe' onClick={handleClick}>Upgrade Plan</a>;
};

export default CheckoutButton;
import axios from 'axios'
import { useEffect, useState } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, LineChart, Line } from 'recharts'
import Header from '../global/Header'

const monthlyData = [
    { name: 'Jan', users: 4000 },
    { name: 'Feb', users: 3000 },
    { name: 'Mar', users: 2000 },
    { name: 'Apr', users: 2780 },
    { name: 'May', users: 1890 },
    { name: 'Jun', users: 2390 },
]

const topicTrendsData = [
    { name: 'Business', value: 400 },
    { name: 'Technology', value: 300 },
    { name: 'Finance', value: 200 },
    { name: 'Marketing', value: 150 },
    { name: 'Leadership', value: 100 },
]

const satisfactionData = [
    { time: '9AM', score: 4.2 },
    { time: '12PM', score: 4.5 },
    { time: '3PM', score: 4.0 },
    { time: '6PM', score: 4.3 },
    { time: '9PM', score: 4.7 },
]

const COLORS = ['#58009f', '#ADADAD', '#8884d8', '#82ca9d', '#ffc658']

function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function convertToMinutesAndSeconds(minutes) {
    const wholeMinutes = Math.floor(minutes);
    const seconds = Math.round((minutes - wholeMinutes) * 60);

    return {
        minutes: wholeMinutes,
        seconds: seconds
    };
}

export default function EnhancedAnalyticsDashboard() {
    const [activeTab, setActiveTab] = useState('Overview')

    const [closed, setClosed] = useState(false)
    const [shown, setShown] = useState(2)

    const [analytics, setAnalytics] = useState({
        'total_users': 69,
        'new_users': 69,
        'active_subscribers': 69,
        'total_revenue': 69,
        'basic_users': 79,
        'premium_users': 69,
        'conversion_rate': 69,
        'average_messages_per_user': 69,
        'average_sessions_per_user': 69,
        'average_session_time': 69
    })

    const userTypeData = [
        { name: 'Paying Users', value: analytics["premium_users"] },
        { name: 'Free Users', value: analytics["basic_users"] },
    ]

    useEffect(() => {
        axios.get("https://api.alphatwin.ai/api/analytics_overview")
            .then(r => {
                setAnalytics(r.data)
                console.log(r.data)
            })
            .catch(e => console.log(e))
    }, [])


    const renderContent = () => {
        switch (activeTab) {
            case 'Overview':
                return (
                    <div style={{ display: "flex", flexDirection: "column", gap: '20px' }}>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8" style={{
                            display: 'flex',
                            // flexDirection: "column",
                            width: "100%",
                            gap: "20px"
                        }}>
                            {/* Monthly Users Chart */}
                            <div className="card bg-[#151618] p-4 rounded-lg" style={{
                                paddingRight: "20px",
                                width: "100%",
                                justifyContent: "space-between"
                            }}>
                                <h3 className="text-xl font-semibold mb-4" style={{
                                    fontWeight: 700
                                }}>Monthly Users</h3>
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart data={monthlyData}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Bar dataKey="users" fill="#58009f" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>

                            {/* User Type Distribution */}
                            <div className="bg-[#151618] p-4 rounded-lg card" style={{
                                paddingRight: "20px",
                                width: "100%"
                            }}>
                                <h3 className="text-xl font-semibold mb-4">User Type Distribution</h3>
                                <ResponsiveContainer width="100%" height={300}>
                                    <PieChart>
                                        <Pie
                                            data={userTypeData}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            dataKey="value"
                                            label
                                        >
                                            {userTypeData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                                <div className="flex justify-center mt-4" style={{
                                    display: "flex",
                                    justifyContent: "space-evenly"
                                }}>
                                    {userTypeData.map((entry, index) => (
                                        <div key={`legend-${index}`} className="flex items-center mx-2" style={{
                                            display: "flex",
                                            gap: ".6em",
                                            alignItems: "center"
                                        }}>
                                            <div className="w-3 h-3 mr-1" style={{
                                                backgroundColor: COLORS[index % COLORS.length],
                                                width: "20px", height: "20px"
                                            }}></div>
                                            <span>{entry.name}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Key Metrics */}
                        <div className="bg-[#151618] p-4 rounded-lg mt-8 card" style={{
                            display: "flex",
                            gap: "10px"
                        }}>
                            <h3 className="text-xl font-semibold mb-4">Key Metrics</h3>
                            <div className="grid grid-cols-2 md:grid-cols-4 gap-4" style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingRight: "23px"
                            }}>
                                <div>
                                    <p className="text-[#ADADAD]" style={{
                                        color: "#adadad"
                                    }}>Avg. Messages/User</p>
                                    <p className="text-2xl font-bold" style={{
                                        fontSize: "xx-large",
                                        fontWeight: "600"
                                    }}>{analytics['average_messages_per_user']}</p>
                                </div>

                                <div>
                                    <p className="text-[#ADADAD]" style={{
                                        color: "#adadad"
                                    }}>Avg. Sessions/User</p>
                                    <p className="text-2xl font-bold" style={{
                                        fontSize: "xx-large",
                                        fontWeight: "600"
                                    }}>{analytics['average_sessions_per_user']}</p>
                                </div>
                                <div>
                                    <p className="text-[#ADADAD]" style={{
                                        color: "#adadad"
                                    }}>Avg. Session Time</p>
                                    <p className="text-2xl font-bold" style={{
                                        fontSize: "xx-large",
                                        fontWeight: "600"
                                    }}>
                                        {analytics["average_session_time"]}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-[#ADADAD]" style={{
                                        color: "#adadad"
                                    }}>Conversion Rate</p>
                                    <p className="text-2xl font-bold" style={{
                                        fontSize: "xx-large",
                                        fontWeight: "600"
                                    }}>{analytics["conversion_rate"]}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            case 'Query Analysis':
                return (
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1em"
                    }}>
                        {/* Topic Trends */}
                        <div className="bg-[#151618] p-4 rounded-lg mb-8 card">
                            <h3 className="text-xl font-semibold mb-4">Topic Trends</h3>
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart data={topicTrendsData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey="value" fill="#58009f" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>

                        {/* Top Queries and Dissatisfaction Triggers */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8" style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "1em"
                        }}>
                            <div className="bg-[#151618] p-4 rounded-lg card" style={{
                                width: "100%",
                                gap: "1em",
                            }}>
                                <h3 className="text-xl font-semibold mb-4" style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: '10px',
                                    color: "#9AE19D"
                                }}>
                                    <img src='gup.svg' width={20} />
                                    Top Queries
                                </h3>
                                <ul className="space-y-2 queries" style={{
                                }}>
                                    <li>1. How to start a business?</li>
                                    <li>2. Tips for effective networking</li>
                                    <li>3. Best investment strategies</li>
                                    <li>4. How to scale a startup</li>
                                    <li>5. Negotiation techniques</li>
                                </ul>
                            </div>
                            <div className="bg-[#151618] p-4 rounded-lg card" style={{
                                width: "100%",
                                gap: "1em",
                            }}>
                                <h3 className="text-xl font-semibold mb-4" style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: '10px',
                                    color: "#DB5461"
                                }}>
                                    <img src='gdown.svg' width={20} />
                                    Common Dissatisfaction Triggers
                                </h3>
                                <ul className="space-y-2 queries">
                                    <li>1. Lack of specific industry examples</li>
                                    <li>2. Outdated financial advice</li>
                                    <li>3. Vague responses to technical questions</li>
                                    <li>4. Inconsistent advice on legal matters</li>
                                    <li>5. Limited personalization in responses</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            case 'User Satisfaction':
                return (
                    <>
                        {/* Satisfaction Score Over Time */}
                        <div className="bg-[#151618] p-4 rounded-lg mb-8">
                            <h3 className="text-xl font-semibold mb-4">Satisfaction Score Over Time</h3>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={satisfactionData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="time" />
                                    <YAxis domain={[0, 5]} />
                                    <Tooltip />
                                    <Line type="monotone" dataKey="score" stroke="#58009f" />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>

                        {/* Most Satisfactory Responses */}
                        <div className="bg-[#151618] p-4 rounded-lg mb-8">
                            <h3 className="text-xl font-semibold mb-4">Most Satisfactory Responses</h3>
                            <ul className="space-y-2">
                                <li>1. Detailed step-by-step guides on business processes</li>
                                <li>2. Personalized advice based on user's industry and experience</li>
                                <li>3. Up-to-date market trends and analysis</li>
                                <li>4. Practical examples of successful business strategies</li>
                                <li>5. Clear explanations of complex financial concepts</li>
                            </ul>
                        </div>
                    </>
                )
            default:
                return null
        }
    }

    useEffect(() => {
        if (window.innerWidth < 1300) setClosed(true)
    }, [])

    const user = null;

    return (<>
        <Header />
        <div className='analyticsBar'>
            <div className='bars bars2' onClick={() => {
                setClosed(c => !c)
                setShown(s => s === 0 ? 1 : 0)
            }}>
                <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-lg mx-2 text-token-text-secondary"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 8C3 7.44772 3.44772 7 4 7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9 20 9H4C3.44772 9 3 8.55228 3 8ZM3 16C3 15.4477 3.44772 15 4 15H14C14.5523 15 15 15.4477 15 16C15 16.5523 14.5523 17 14 17H4C3.44772 17 3 16.5523 3 16Z" fill="currentColor"></path></svg>
            </div>
            <div className='bars bars1' onClick={() => {
                setClosed(c => !c)
                setShown(s => s === 1 ? 2 : 1)
            }}>
                {shown === 2 ?
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="26px" width="26px" xmlns="http://www.w3.org/2000/svg"><path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34zm192-34l-136-136c-9.4-9.4-24.6-9.4-33.9 0l-22.6 22.6c-9.4 9.4-9.4 24.6 0 33.9l96.4 96.4-96.4 96.4c-9.4 9.4-9.4 24.6 0 33.9l22.6 22.6c9.4 9.4 24.6 9.4 33.9 0l136-136c9.4-9.2 9.4-24.4 0-33.8z"></path></svg>
                    :
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="26px" width="26px" xmlns="http://www.w3.org/2000/svg"><path d="M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z"></path></svg>
                }
            </div>
        </div>
        <div className="body flex h-screen bg-[#0A0A0A] text-white" style={{
            display: "flex",
            background: "#0A0A0A",
            color: "white",
            fontFamily: "",
            // height: "100svh",
            gap: "1em",
            fontSize: "16px",
            fontFamily: "Open Sans",
            // fontWeight: 700,
            paddingBottom: "60px"
        }}>
            {/* Left Sidebar */}
            <div className={`leftBar ${!closed && "closedOff"} ${shown === 0 ? "shown" : "notShown"}`}>
                <div className="card" style={{ height: "100%" }}>
                    <h2 className="text-2xl font-bold mb-4">Analytics Menu</h2>
                    <div className="space-y-2 menu">
                        {['Overview', 'Query Analysis', 'User Satisfaction'].map((tab) => (
                            <button
                                key={tab}
                                className={`${tab === activeTab && "selected"} w-full text-left p-2 menuButton hoverable rounded ${activeTab === tab ? 'bg-[#58009f]' : 'hover:bg-[#58009f]'}`}
                                onClick={() => setActiveTab(tab)}
                                style={{ width: "90%" }}
                            >
                                {tab}
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className={`middleBar ${!closed && "closedOff"} ${shown === 1 ? "shown" : "notShown"}`}>
                {renderContent()}
            </div>

            {/* Right Sidebar */}
            <div className={`rightBar ${closed && "closed"} ${shown === 2 ? "shown" : "notShown"}`} style={{
                // width: "max-content"
            }}>
                <div className="card" style={{
                    gap: "1em",
                    height: "100%"
                }}>

                    <h2 className="text-2xl font-bold mb-4" style={{
                        display: "flex", alignItems: "center", gap: '.5em'
                    }}>
                        {/* <button className="back icon"
                            onClick={() => {
                                setClosed(c => !c)
                                setShown(1)
                            }}>
                            <img src='back.svg' className='dots' height='16' />
                        </button> */}
                        Quick Stats</h2>

                    <div className="bg-[#0A0A0A] p-4 rounded-lg" style={{
                        background: "#0A0A0A",
                        marginLeft: ".5em",
                        marginRight: "1em",
                        paddingInline: "1em",
                        paddingBlock: ".5em",
                        borderRadius: "8px"
                    }}>
                        <p className="text-[#ADADAD]" style={{
                            color: "#ADADAD"
                        }}>Total Users</p>
                        <p className="text-3xl font-bold" style={{
                            fontSize: "xx-large",
                            fontWeight: 600
                        }}>{formatNumberWithCommas(analytics["total_users"])}</p>
                    </div>

                    <div className="bg-[#0A0A0A] p-4 rounded-lg" style={{
                        background: "#0A0A0A",
                        marginLeft: ".5em",
                        marginRight: "1em",
                        paddingInline: "1em",
                        paddingBlock: ".5em",
                        borderRadius: "8px"
                    }}>
                        <p className="text-[#ADADAD]" style={{
                            color: "#ADADAD"
                        }}>New Users (This Month)</p>
                        <p className="text-3xl font-bold" style={{
                            fontSize: "xx-large",
                            fontWeight: 600
                        }}>{analytics["new_users"]}</p>
                    </div>

                    <div className="bg-[#0A0A0A] p-4 rounded-lg" style={{
                        background: "#0A0A0A",
                        marginLeft: ".5em",
                        marginRight: "1em",
                        paddingInline: "1em",
                        paddingBlock: ".5em",
                        borderRadius: "8px"
                    }}>
                        <p className="text-[#ADADAD]" style={{
                            color: "#ADADAD"
                        }}>Active Subscribers</p>
                        <p className="text-3xl font-bold" style={{
                            fontSize: "xx-large",
                            fontWeight: 600
                        }}>{analytics['active_subscribers']}</p>
                    </div>

                    <div className="bg-[#0A0A0A] p-4 rounded-lg" style={{
                        background: "#0A0A0A",
                        marginLeft: ".5em",
                        marginRight: "1em",
                        paddingInline: "1em",
                        paddingBlock: ".5em",
                        borderRadius: "8px"
                    }}>
                        <p className="text-[#ADADAD]" style={{
                            color: "#ADADAD"
                        }}>Revenue (This Month)</p>
                        <p className="text-3xl font-bold" style={{
                            fontSize: "xx-large",
                            fontWeight: 600
                        }}>{analytics["total_revenue"]}</p>
                    </div>

                </div>
            </div>
        </div>
    </>
    )
}